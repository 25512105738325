import React, { Component } from 'react';
import Board from './pages/board';
import { Result } from 'antd';

import './App.css';

class App extends Component {
  render() {
    if (!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      // window.location.href="移动端url";
      return (<Result
        status="warning"
        title="该页面只能在移动端运行，请去手机打开."
      />)
    }
    document.title = '留言板';
    return (
      <div className="App" style={{ padding: "16px" }}>
        <Board />
      </div>
    )
  }
}

export default App;
