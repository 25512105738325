import React from 'react';
import { Comment, Badge, List, Form, Input, Button, Space, Card } from 'antd';


const MessageList = (props) => {
    const { message, status, response, remove } = props;
    console.log(message);
    return (
        <List
            className="comment-list"
            itemLayout="horizontal"
            dataSource={message}
            style={{ textAlign: 'left' }}
            renderItem={message => (
                <Badge.Ribbon text={message.top === 1 ? "置顶" : false} color={message.top === 1 ? "purple" : false}>
                    <Card
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: '8px', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                    >
                        <Comment
                            author={message.nickname}
                            avatar={message.avatar}
                            content={message.content}
                            datetime={message.create_time}
                        >
                            {message.status === 1 && message.response && (<Badge.Ribbon text="作者" >
                                <Comment
                                    author={message.author_name}
                                    avatar={message.author_avatar}
                                    content={message.response}
                                    datetime={message.response_time}
                                >
                                    {status === 1 && (<Button size="small" type="danger" onClick={() => remove(message.id)}>删除</Button>)}

                                </Comment></Badge.Ribbon>
                            )}
                            {message.status === 0 && status === 1 && (
                                <Form
                                    onFinish={(resp) => response(message.id, resp.response)}
                                >
                                    <Form.Item
                                        name="response"
                                    >
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <Input.TextArea
                                                placeholder={`回复${message.nickname}`}
                                                block
                                            />
                                            <Space>
                                                <Button size="small" type="primary" htmlType="submit"  >提交</Button>
                                                <Button size="small" type="danger" onClick={() => remove(message.id)}>删除</Button>
                                            </Space>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            )}
                        </Comment>
                    </Card>
                </Badge.Ribbon>
            )}
        />
    );
}

export default MessageList;