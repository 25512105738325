import React, { useState } from 'react';
import { Spin, message, Divider } from 'antd';
import Ask from '../component/ask';
import MessageList from '../component/list';

import request from '../service/request';

const Board = () => {
    const [config, setConfig] = useState({
        title: 'Ask me anything',
        tips: 'test'
    });
    const [loading, setLoading] = useState(true);
    const [requesting, setRequesting] = useState(false);
    const [message_list, setMessage] = useState([]);

    const ask = async (content) => {
        if (content === '' || content === undefined) {
            message.error('内容不能为空');
            return;
        }
        setRequesting(true);
        const resp = await request('applet/ask', 'POST', { content });
        if (resp.code === 0) {
            message.success('留言成功，系统为你分配昵称' + resp.nickname + '，回复后显示', 20);
        } else {
            message.error(resp.errmsg);
        }
        console.log(resp);
        setRequesting(false);
        initialize();
    }

    const remove = async (id) => {
        const resp = await request('applet/delete', 'POST', { id });
        if (resp.code === 0) {
            message.success('删除成功');
        } else {
            message.error(resp.errmsg);
        }
        initialize();
    }

    const response = async (id, content) => {
        if (content === '' || content === undefined) {
            message.error('内容不能为空');
            return;
        }
        const resp = await request('applet/response', 'POST', { id, content });
        if (resp.code === 0) {
            message.success('回复成功');
        } else {
            message.error(resp.errmsg);
        }
        console.log(resp);
        setLoading(true);
        initialize();
        setLoading(false);
    }

    const initialize = () => {
        request('applet/initialize').then(resp => {
            setLoading(false);
            setConfig({ ...resp.config, status: resp.status });
            setMessage(resp.message);
        })
    }

    if (loading) {
        initialize();
    }

    return (
        <>
            <Spin
                spinning={loading || requesting}
            >
                <Ask config={config} ask={ask} />
            </Spin>
            <Divider />
            <MessageList
                status={config.status}
                message={message_list}
                remove={remove}
                response={response}
            />
        </>
    );
}

export default Board;