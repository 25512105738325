import { React } from 'react';
import { Button, Input, Typography, Space, Form } from 'antd';

const { Title } = Typography;
const { TextArea } = Input;

const Ask = (props) => {
    const { config, ask } = props;
    console.log(config);
    document.title = config.page_title;
    return (
        <Typography style={{ marginTop: '50px', backgroundColor: 'rgba(211, 211, 211, 0.5)', borderRadius: '8px', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' }}>
            <Space direction="vertical" style={{ width: '90%' }}>
                <Title style={{ marginTop: '10px' }}level={2}>{config.title}</Title>
                <Form
                    onFinish={(content) => {
                        ask(content.content)
                    }}
                >
                    <Form.Item
                        name="content"
                    >
                        <TextArea
                            showCount
                            bordered={false}
                            style={{ borderBottom: '1px dashed', borderBottomColor: '#D3D3D3' }}
                            allowClear={true}
                            placeholder={config.tips}
                            autoFocus={true}
                            autoSize={true}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>提交</Button>
                    </Form.Item>
                </Form>
            </Space>
        </Typography>
    )
}

export default Ask;
