const server = 'https://board-api.xcookie.org';

const request = async (url, method = "GET", data = {}) => {
    if (method === 'GET') {
        const resp = await fetch(`${server}/${url}`);
        return resp.json();
    } else if (method === 'POST') {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        const resp = await fetch(`${server}/${url}`, options);
        return resp.json();
    }
    return { code: -1, msg: 'unrecognized method' };
}

export default request;